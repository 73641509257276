import { makeTrans } from 'utils/translations';

export const SERVER_ERROR = {
  message: makeTrans('Oops, something went wrong!'),
  type: 'error'
};

export const SERVER_CANCEL_COMMUNICATIONS_ERROR = {
  message: makeTrans('Oops! We could not cancel your communications. Please try again or contact support.'),
  type: 'error'
};

export const SERVER_ACTION_SUCCESS_UPDATE = {
  message: makeTrans('Successfully updated!'),
  type: 'success'
};

export const SERVER_ACTION_SUCCESS_CREATED = {
  message: makeTrans('Successfully created!'),
  type: 'success'
};

export const VALIDATION_ACCOUNT_SUCCESS = {
  message: makeTrans('Account successfully validated!'),
  type: 'success'
};

export const CONFIRM_HOSPITAL_PATIENT_SUCCESS = {
  message: makeTrans('Patient successfully verified!'),
  type: 'success'
};

export const CONFIRM_HOSPITAL_PATIENT_ERROR = {
  message: makeTrans('Oops! Failed to confirm your patient! Please try again.'),
  type: 'error'
};

export const NEW_PATIENT_SUCCESS_CREATE = {
  message: makeTrans('Patient successfully created'),
  type: 'success'
};

export const NEW_PATIENT_SUCCESS_ADD = {
  message: makeTrans('Patient successfully added'),
  type: 'success'
};

export const FETCH_USER_INSTITUTIONS_ERROR = {
  message: makeTrans('Oops! Failed to get your institutions! Please try again.'),
  type: 'error'
};

export const RESEND_PHONE_SMS_ACCOUNT_VALIDATION_SUCCESS = {
  message: makeTrans('New code has been sent to your phone number'),
  type: 'success'
};

export const RESEND_EMAIL_ACCOUNT_VALIDATION_SUCCESS = {
  message: makeTrans('New email has been sent to your email account'),
  type: 'success'
};

export const PHONE_ACCOUNT_VALIDATION_SUCCESS = {
  message: makeTrans('Phone validated successfully'),
  type: 'success'
};

export const EVENT_ADDED_SUCCESS = {
  message: makeTrans('Event added successfully'),
  type: 'success'
};

export const EVENT_UPDATED_SUCCESS = {
  message: makeTrans('Event updated successfully'),
  type: 'success'
};

export const EXAM_ADDED_SUCCESS = {
  message: makeTrans('Exam added successfully'),
  type: 'success'
};

export const EXAM_UPDATED_SUCCESS = {
  message: makeTrans('Exam updated successfully'),
  type: 'success'
};

export const SERVER_ACTION_SUCCESS_REPORT = {
  message: makeTrans('Movement reported successfully'),
  type: 'success'
};

export const UNSUBSCRIBED_SUCCESS = {
  message: makeTrans('Unsubscribed successfully'),
  type: 'success'
};

export const ERROR_DELETE_ITEM = {
  message: makeTrans('Oops! Failed to remove item! Please try again.'),
  type: 'error'
};

export const PROVIDER_DISCONNECTED_SUCCESS = {
  message: makeTrans('Successfully disconnected!'),
  type: 'success'
};

export const PROVIDER_CONNECTED_SUCCESS = {
  message: makeTrans('Successfully connected!'),
  type: 'success'
};

export const DISEASE_TEAM_MEMBERS_ERROR = {
  message: makeTrans('Oops! Failed to load your clinicians!'),
  type: 'error'
};

export const MEASUREMENT_SEND_SUCCESS = {
  message: makeTrans('Measurement successfully sent!'),
  type: 'success'
};

export const MEASUREMENT_SEND_ERROR = {
  message: makeTrans('Oops! Failed to send measurement!'),
  type: 'error'
};

export const PROM_PRESCRIBE_SUCCESS_MESSAGE = {
  message: makeTrans('Prescribed successfully!'),
  type: 'success'
};

export const PROM_PRESCRIBE_WITH_TYPE_SUCCESS_MESSAGE = type => ({
  message: makeTrans(`Prescribed ${type} successfully!`),
  type: 'success'
});

export const ERROR_PATIENT_ADD_FEATURED_STATUS = {
  message: makeTrans('Oops! We can’t add this patient to watch list. Please try again or contact support.'),
  type: 'error'
};
export const ERROR_PATIENT_REMOVE_FEATURED_STATUS = {
  message: makeTrans('Oops! We can’t remove this patient to watch list. Please try again or contact support.'),
  type: 'error'
};

export const ERROR_UPLOAD_USER_AVATAR = {
  message: makeTrans('Oops! We couldn´t update your profile image. Please try again or contact support.'),
  type: 'error'
};

export const SUCCESS_UPLOAD_USER_AVATAR = {
  message: makeTrans('Profile picture changed successfully!'),
  type: 'success'
};

export const ERROR_ADD_CARE_DOCTOR = {
  message: makeTrans('Oops! We couldn´t add this care doctor to patient. Please try again or contact support.'),
  type: 'error'
};

export const SUCCESS_ADD_CARE_DOCTOR = {
  message: makeTrans('Care doctor defined successfully!'),
  type: 'success'
};

export const ERROR_REMOVE_CARE_DOCTOR = {
  message: makeTrans('Oops! We couldn´t remove this care doctor. Please try again or contact support.'),
  type: 'error'
};

export const SUCCESS_REMOVE_CARE_DOCTOR = {
  message: makeTrans('Care doctor removed successfully!'),
  type: 'success'
};

export const SUCCESS_EXPORT_CLINICAL_DATA = {
  message: makeTrans('We are processing your request. You will be notified shortly!'),
  type: 'success'
};

export const PREFERENCES_MEMBER_UPDATED_SUCCESS = {
  message: makeTrans('Preferences were successfully updated!'),
  type: 'success'
};

export const PREFERENCES_MEMBER_UPDATED_FAILURE = {
  message: makeTrans('Oops! Preferences could not be updated!'),
  type: 'error'
};

export const REQUIRED_MSG = 'This field is required';
export const EMPTY_VALUE_MSG = 'Empty values are not allowed';
export const EMAIL_INVALID_MSG = 'Please insert a valid email';
export const INVALID_COUNTRY_PHONE_MSG = 'This country is not valid for the given number';
export const INVALID_DATE = 'This date is invalid';
export const INVALID_VAT_NUMBER = 'Invalid VAT Number';

export const PASSWORD_CHECK = {
  strengthHint: makeTrans('Password strength'),
  strengthLowHint: makeTrans("You can't use this password, make it safer"),
  strengthMediumHint: makeTrans('Valid, but you can do better!'),
  strengthHighHint: makeTrans('Strong password. The force is with you!'),
  sizeHint: makeTrans('At least 8 characters'),
  numbersHint: makeTrans("Don't use numbers only"),
  similarHint: makeTrans('The password is too similar to your email or phone number')
};

export const SUCCESS_COPY_TO_CLIPBOARD = {
  message: makeTrans('Content copied to clipboard'),
  type: 'success'
};

export const SUCCESS_SEND_COMMUNICATION = {
  message: makeTrans('Communication sent successfully'),
  type: 'success'
};

export const ERROR_SEND_COMMUNICATION = {
  message: makeTrans('Failed to send communication'),
  type: 'error'
};

export const ERROR_START_QUESTIONNAIRE = {
  message: makeTrans('Failed to start the questionnaire'),
  type: 'error'
};

export const ERROR_SUBMIT_QUESTIONNAIRE_RESPONSE = {
  message: makeTrans('An error occurred, it was not possible to submit the questionnaire. Please try again.'),
  type: 'error'
};

export const ERROR_PATIENT_SEARCH = {
  message: makeTrans(
    'Please make sure your personal data is correct. If it is, please contact your clinical team about this.'
  ),
  type: 'error'
};

export const ERROR_DEVICE_CONNECT = {
  message: makeTrans('Unable to connect this device using your email'),
  type: 'error'
};

export const ERROR_DEVICE_LOGIN = {
  message: makeTrans(
    'Please verify the temporary code. The code entered is not valid. Please check if it is written correctly and try again'
  ),
  type: 'error'
};

export const SUCCESS_DEVICE_LOGIN = {
  message: makeTrans('Device connected. Patients can now start answering health questionnaires on this device.'),
  type: 'success'
};
